<template>
  <a-modal
    :title="title"
    width="40%"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-spin :spinning="loading">
      <a-form-model ref="form" :model="entity" :rules="rules" v-bind="layout">
        <a-form-model-item label="工序名称" prop="Name">
          <a-input v-model="entity.Name" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="工序编号" prop="SN">
          <a-input v-model="entity.SN" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="工时(h)" prop="Duration">
          <a-input v-model="entity.Duration" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="定额" prop="Money">
          <a-input v-model="entity.Money" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="技术要求" prop="Require">
          <a-input v-model="entity.Require" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="Remarks">
          <a-input v-model="entity.Remarks" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="类型" prop="ProcessType">
          <a-select :default-value="entity.ProcessType" style="width: 120px" @change="handleTypeChange">
            <a-select-option :value="0">生产</a-select-option>
            <a-select-option :value="1">详细配置</a-select-option>
            <a-select-option :value="2">试模</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
export default {
  props: {
    parentObj: Object,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      loading: false,
      entity: {},
      rules: {
        Name: [{ required: true, message: '请输入工序名称', trigger: 'change' }],
      },
      title: '',
    }
  },
  methods: {
    handleTypeChange(e) {
      console.log(e)
      this.entity.ProcessType = e
    },
    init() {
      this.visible = true
      this.entity = { ProcessType: 0 }
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    openForm(id, _title) {
      this.init()

      if (id) {
        this.loading = true
        this.$http.post('/Main/Process/GetTheData', { id: id }).then(resJson => {
          this.loading = false

          this.entity = resJson.Data
        })
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/Main/Process/SaveData', this.entity)
          .then(resJson => {
            this.loading = false

            if (resJson.Success) {
              this.$message.success('操作成功!')
              this.visible = false

              this.parentObj.getDataList()
            } else {
              this.$message.error(resJson.Msg)
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>
